@import 'styles/mixins';

.videoIframeContainer {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

  @include sm {
    width: 90vw;
    max-width: 100vh;
  }

  @include md {
    width: 90vw;
    max-width: 140vh;
  }
}

.videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
