@import 'styles/mixins';

.root {
  width: 100%;
  @include md {
    padding-right: 25px;
  }
}

.subtitle {
  font-size: 1.3rem;
  margin-bottom: 5px;
  margin-top: 10px;

  @include md {
    margin-top: 20px;
  }
}
