@import 'styles/mixins';

.playIntroButton {
  margin: 30px 0 60px 0;

  @include md {
    margin-top: 50px;
    margin-bottom: 0;
  }
}
